import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const Webinar2011WinnerBraedenBenedict = () => (
  <Layout
    title="Young Scientist Challenge 101: Webinar featuring 2011 Winner Braeden Benedict"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            Young Scientist Challenge 101: Webinar featuring 2011 Winner Braeden
            Benedict
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">
              Young Scientist Challenge 101: Webinar featuring 2011 Winner
              Braeden Benedict
            </h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <div className="videoWrapper">
                <Link
                  to="../ysc-webinar-2012-winner-deepika-kurup"
                  className="previous"
                >
                  <Icon name="arrowleftgray" />
                </Link>
                <Video youTubeID="wFScUubPSPE" className="youtube"></Video>
              </div>
            </div>
            <h3>
              Learn all about the Young Scientist Challenge in this 2012 webinar
              featuring 2011 Grand Prize winner Braeden Benedict.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default Webinar2011WinnerBraedenBenedict;
